var tableColumns = [{
  title: "支付ID",
  dataIndex: "id",
  key: "id",
  width: 140
}, {
  title: "支付服务名称",
  dataIndex: "payservicename",
  key: "payservicename",
  width: 120
}, {
  title: "关联任务名称",
  dataIndex: "taskname",
  key: "taskname",
  width: 100
}, {
  title: "支付金额",
  dataIndex: "money",
  key: "money",
  width: 100
}, {
  title: "Token类型",
  dataIndex: "tasktoken",
  key: "tasktoken",
  width: 180,
  scopedSlots: {
    customRender: "tasktoken"
  }
}, {
  title: "用户昵称",
  dataIndex: "username",
  key: "username",
  width: 180
}, {
  title: "支付时间",
  dataIndex: "paytime",
  key: "paytime",
  width: 120,
  scopedSlots: {
    customRender: "paytime"
  }
}];
export { tableColumns };